export const MenuItems =[
    {
        title:"Home",
        url:"/",
        cName:"nav-links",
        icon: "fa-solid fa-house-user"
    },
    {
        title:"About",
        url:"/about",
        cName:"nav-links",
        icon: "fa-solid fa-circle-info"
    },
    {
        title:"Service",
        url:"/service",
        cName:"nav-links",
        icon: "fa-solid fa-briefcase"
    },
    {
        title:"Contact",
        url:"/contact",
        cName:"nav-links",
        icon: "fa-solid fa-address-book"
    },
    {
        title:"Career",
        url:"/career",
        cName:"nav-links",
        icon: "fa-solid fa-user-tie"
    },
    // {
    //     title:"Sign-Up",
    //     url:"/signup",
    //     cName:"nav-links-mobile",
    //     // icon: "fa-solid fa-address-book"
    // },
]